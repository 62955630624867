import React, { Component } from 'react';

class Resume extends Component {

    getConfigProperty(uri) {
        const segments = uri.split('.');

        let currentProperty = this.props.config;

        for (let i = 0; i < segments.length; i++) {
            currentProperty = currentProperty[segments[i]];
        }

        return currentProperty;
    }

    renderEducation() {
        return this.props.data
            ? this.props.data.education.map((s, i) => {
                return <div key={s.school + i}><h5>{s.school}</h5>
                    <p className="info">{s.degree} <span>&bull;</span>
                        <em className="date">{s.graduated}</em>
                    </p>
                </div>
            })
            : '';
    }

    renderWork() {
        return this.props.data
            ? this.props.data.work.map((w, i) => {
                return <div key={w.company + i}><h5>{w.company}</h5>
                    <p className="info">{w.title}<span>&bull;</span>
                        <em className="date">{w.years}</em>
                    </p>
                </div>
            })
            : '';
    }

    renderSkills() {
        return this.props.data
            ? this.props.data.skills.map((s, i) => {
                const className = 'bar-expand ' + s.name.toLowerCase();
                return <li key={s.name + i}>
                    <span style={{ width: s.levelPercentage }} className={className}></span>
                    {/* <em><img alt={s.logo} src={s.logo} height={30} width={30} style={{marginLeft:10, marginRight: 20}}></img></em> */}
                    <em>{s.name}</em>
                </li>
            })
            : '';
    }

    renderCerts() {
        return this.props.data
            ? this.props.data.certificates.map((group, i) => {
                return <div class="row">
                    <div className="three columns header-col">
                        <h3><span>{group.category}</span></h3>
                    </div>
                    <div className="nine columns main-col">
                        <ul className="certs">
                            {this.renderCertsGroup(group)}
                        </ul>
                    </div>
                </div>
            })
            : '';
    }

    renderCertsGroup(group) {
        return group.certs.map((c, i) => {
            return <li key={c.name}>
                <a href={c.credlyLink} target='_blank'>
                    <img src={this.getConfigProperty(c.badge)} alt={c.name} title={c.name} height={200} width={150}></img>
                    <span>{c.shortcut}</span>
                </a>
            </li>
        });
    }

    render() {
        return (
            <section id="resume">
                <div className="row education">
                    <div className="three columns header-col">
                        <h1><span>Education</span></h1>
                    </div>
                    <div className="nine columns main-col">
                        <div className="row item">
                            <div className="twelve columns">
                                {this.renderEducation()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row work" id="work">
                    <div className="three columns header-col">
                        <h1><span>Work</span></h1>
                    </div>
                    <div className="nine columns main-col">
                        {this.renderWork()}
                    </div>
                </div>
                <div className="row skill" id="skills">
                    <div className="three columns header-col">
                        <h1><span>Tech Skills</span></h1>
                    </div>
                    <div className="nine columns main-col">
                        <div className="bars">
                            <ul className="skills">
                                {this.renderSkills()}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row certs" id="certs">
                    <div className="three columns header-col">
                        <h1><span>Certificates</span></h1>
                    </div>
                    <hr />
                    {this.renderCerts()}
                </div>
            </section>
        );
    }
}

export default Resume;
